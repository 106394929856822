import React from "react"
import { useApp } from "../../hooks/useApp"
import { useShopify } from "../../hooks/useShopify"

import { getAdditionalText } from "../../utils/getAdditionalText"

export const withCareers = Component => ({ name = "Careers", page, jobs }) => {
  const { edgeNormaliser } = useShopify()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    page: {
      careers: {
        additionalCurrentVacanciesTitleText,
        additionalPositionTitleText,
        additionalWorkTypeTitleText,
        additionalLocationTitleText,
      },
    },
  } = getAdditionalText()

  const { noOpenJobMessage } = page || {}

  const formattedJobs = edgeNormaliser(jobs)?.map(job => ({
    ...job,
    id: job._id,
    url: `${routes.CAREER}/${job?.slug?.current}`,
  }))

  Component.displayName = name

  return (
    <Component
      page={page}
      noOpenJobMessage={noOpenJobMessage}
      jobs={formattedJobs}
      additionalCurrentVacanciesTitleText={additionalCurrentVacanciesTitleText}
      additionalPositionTitleText={additionalPositionTitleText}
      additionalWorkTypeTitleText={additionalWorkTypeTitleText}
      additionalLocationTitleText={additionalLocationTitleText}
    />
  )
}
