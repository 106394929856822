import React from "react"
import { withCareers } from "./withCareers"
import { Sections } from "../Sections/Sections"
import { Wrapper, Title, Header, SubTitle, Jobs } from "./CareersStyles"
import { CareersJob } from "./Job/CareersJob"
import { Body } from "../Styled/Text"

export const Careers = withCareers(
  ({
    page,
    noOpenJobMessage,
    jobs,
    additionalCurrentVacanciesTitleText,
    additionalPositionTitleText,
    additionalWorkTypeTitleText,
    additionalLocationTitleText,
  }) => (
    <>
      <Sections page={page} />
      <Wrapper>
        <Title>{additionalCurrentVacanciesTitleText}</Title>
        {jobs?.length > 0 ? (
          <>
            <Header>
              <SubTitle cols={3}>{additionalPositionTitleText}</SubTitle>
              <SubTitle cols={1}>{additionalWorkTypeTitleText}</SubTitle>
              <SubTitle cols={1}>{additionalLocationTitleText}</SubTitle>
            </Header>
            <Jobs>
              {jobs?.map(job => (
                <CareersJob key={job?.id} job={job} />
              ))}
            </Jobs>
          </>
        ) : (
          <Body>{noOpenJobMessage}</Body>
        )}
      </Wrapper>
    </>
  ),
)
