import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  pb-4 md:pb-12 w-full max-w-md mx-auto px-2 md:px-3
`

export const Title = tw.h2`
  text-20 leading-1.5 tracking-10 font-bold uppercase mb-3-2
`

export const Header = tw.div`
  bg-grey-light px-2-4 py-1-6 hidden md:grid grid-cols-6 gap-x-1-2
`

export const Jobs = tw.div`
  flex flex-col
`

export const SubTitle = styled.h6`
  ${tw`text-14 leading-1.71 tracking-10 font-semibold uppercase`}
  ${({ cols }) => {
    switch (cols) {
      case 1:
        return tw`col-span-1 flex justify-center`
      case 3:
        return tw`col-span-3`
      default:
        break
    }
  }}
`
