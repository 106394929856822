import React from "react"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withCareerJob = Component => ({ name = "CareerJob", job }) => {
  const { position, introduction, url, type, area, email } = job || {}

  const {
    template: {
      career: { additionalApplyButtonText },
    },
    page: {
      careers: { additionalReadFullDescription },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      position={position}
      introduction={introduction}
      url={url}
      type={type}
      area={area}
      email={email}
      additionalReadFullDescription={additionalReadFullDescription}
      additionalApplyButtonText={additionalApplyButtonText}
    />
  )
}
