import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`pt-2-4 md:pt-4 pb-2-4 md:pb-4-8 grid grid-cols-1 md:grid-cols-6 gap-y-1-6 gap-x-1-2 border-b border-grey-light md:px-2-4`}
  :first-of-type {
    ${tw`md:pt-3-2 border-t md:border-t-0`}
  }
`

export const Detail = tw.div`
  col-span-1 md:col-span-3
`

export const Position = tw.div`
  text-20 leading-1.7 mb-1-6
`

export const Introduction = tw.div`
  text-14 leading-1.71 mb-1-6
`

export const Type = tw.div`
  col-span-1 text-14 leading-1.71 flex md:justify-center items-center
`

export const Area = tw.div`
  col-span-1 text-14 leading-1.71 flex md:justify-center items-center
`

export const Apply = tw.div`
  col-span-1 flex justify-center items-center
`

export const Button = tw.button`
  py-0-4 w-full bg-navy text-white border border-navy rounded text-14 leading-1.71 tracking-5 uppercase font-semibold
`
