import React from "react"
import { Link } from "gatsby"

import { withCareerJob } from "./withCareerJob"
import {
  Wrapper,
  Detail,
  Position,
  Introduction,
  Type,
  Area,
  Apply,
  Button,
} from "./CareersJobStyles"
import { CareerPopup } from "../../CareerPopup/CareerPopup"
import {
  Modal,
  ModalOpenButton,
  ModalContentsWithLayout,
} from "../../Modal/Modal"
import { UnderlinedButton } from "../../Styled/Button"

export const CareersJob = withCareerJob(
  ({
    position,
    introduction,
    url,
    type,
    area,
    email,
    additionalReadFullDescription,
    additionalApplyButtonText,
  }) => (
    <Wrapper>
      <Modal>
        <Detail>
          <Position>{position}</Position>
          <Introduction>{introduction}</Introduction>
          <UnderlinedButton
            as={Link}
            to={url}
            title={additionalReadFullDescription}
            size="primary"
          >
            <span>{additionalReadFullDescription}</span>
          </UnderlinedButton>
        </Detail>
        <Type>{type}</Type>
        <Area>{area}</Area>
        <Apply>
          <ModalOpenButton>
            <Button>{additionalApplyButtonText}</Button>
          </ModalOpenButton>
        </Apply>
        <ModalContentsWithLayout layout="career">
          <CareerPopup email={email} />
        </ModalContentsWithLayout>
      </Modal>
    </Wrapper>
  ),
)
