import React from "react"
import { graphql } from "gatsby"

import { Careers as Page } from "../components/Careers/Careers"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageCareers {
      title
      noOpenJobMessage
      content: _rawContent(resolveReferences: { maxDepth: 8 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    jobs: allSanityCareer(sort: { fields: index, order: ASC }) {
      edges {
        node {
          _id
          index
          introduction
          type
          position
          area
          email
          slug {
            current
          }
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("careers_page")

  
  return <Page {...props} {...data} />
}
export default Component
